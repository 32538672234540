import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 
import { AppComponent } from './app.component';
import { ProjectsComponent } from './projects/projects.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { HobbiesComponent } from './hobbies/hobbies.component';
import { ContactMeComponent } from './contact-me/contact-me.component';


@NgModule({
  declarations: [AppComponent, ProjectsComponent, AboutMeComponent, HobbiesComponent, ContactMeComponent ],
  imports: [HttpClientModule, BrowserModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
