import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PROJECTS } from '../data/projects';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  readonly ROOT_URL = 'https://angular-portfolio-9c35d.firebaseio.com';
  
  projects: Observable<any>;
  forbiddenSlugs: string[] = ['e-waste', 'stats-osrs', 'lcbc'];

  constructor(private http: HttpClient) {}

  getProjects(): void {
    this.projects = this.http.get(`${this.ROOT_URL}/projects.json`);
  }

  ngOnInit(): void {
    this.getProjects();
  }

  expandImg($event: Event): void {
    let element = $event.target as HTMLElement;

    if(!element.classList.contains('active-img')){
      let activeImages = document.querySelectorAll('.active-img');
      activeImages.forEach(img => img.classList.remove('active-img'));
    }

    element.classList.toggle('active-img');
  }

  showDetails($event: Event): void {
    let element = $event.target as HTMLElement;

    if(element.classList.contains('show-details')) {
      let parentElement = element.parentElement;
      if (parentElement) parentElement.classList.add('visible');
    }
  }

  isForbiddenSlug(slug: string): boolean {
    return this.forbiddenSlugs.includes(slug);
  }
}
