import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.css']
})
export class HobbiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  exapndImg($event) 
  {
    console.log($event);
    if(!$($event.toElement).hasClass('active-img')){
     $('.active-img').removeClass('active-img');
    }
    $($event.toElement).toggleClass('active-img');
  }
}
